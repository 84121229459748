<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-slate-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-slate-800">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                <div class="flex flex-shrink-0 items-center px-4">
                  <img class="h-8 w-auto mr-3" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=8d724f57-81a0-466f-9a16-93ec2b0933a0" alt="WB-TANK Sp. z o.o." />
              <span class="text-base text-slate-200">WB-TANK Sp. z o.o.</span>
                </div>
                <nav class="mt-5 space-y-1 px-2">
                  <div v-for="item in navigation" :key="item.name" @click.prevent="changeLocation(item.href), sidebarOpen = false" :class="[$route.path === item.href ? 'bg-slate-900 text-white' : 'cursor-pointer text-slate-300 hover:bg-slate-700 hover:text-white', 'transition ease-in-out duration-300 group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                    <component :is="item.icon" :class="[item.current ? 'text-slate-300' : 'text-slate-400 group-hover:text-slate-300', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}
                  </div>
                  <div @click.prevent="logout" :class="['cursor-pointer text-slate-300 hover:bg-slate-700 hover:text-white', 'transition ease-in-out duration-300 group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <LogoutIcon :class="['text-slate-400 group-hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    Wyloguj
                  </div>
                </nav>
              </div>
              <router-link to="/dashboard/profile" @click="sidebarOpen = false" class="flex flex-shrink-0 bg-slate-700 p-4">
                <div class="group block flex-shrink-0">
                  <div class="flex items-center">
                    <div>
                      <img class="inline-block h-10 w-10 rounded-full" :src="`https://avatars.dicebear.com/api/initials/${$store.state.userData.name}_${$store.state.userData.surname}.svg`" alt="" />
                    </div>
                    <div class="ml-3">
                      <p class="text-base font-medium text-white">{{ $store.state.userData.name }} {{ $store.state.userData.surname }}</p>
                      <p class="transition ease-in-out duration-300 text-sm font-medium text-slate-400 group-hover:text-slate-300">Zobacz profil</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col bg-slate-800">
        <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div class="flex flex-shrink-0 items-center px-4">
            <img class="h-8 w-auto mr-3" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=8d724f57-81a0-466f-9a16-93ec2b0933a0" alt="WB-TANK Sp. z o.o." />
            <span class="text-base text-slate-200">WB-TANK Sp. z o.o.</span>
          </div>
          <nav class="mt-5 flex-1 space-y-1 px-2">
            <div v-for="item in navigation" :key="item.name" @click.prevent="changeLocation(item.href)" :class="[$route.path === item.href ? 'bg-slate-900 text-white' : 'text-slate-300 cursor-pointer hover:bg-slate-700 hover:text-white', 'transition ease-in-out duration-300 group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[item.current ? 'text-slate-300' : 'text-slate-400 group-hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </div>
            <div @click.prevent="logout" :class="['cursor-pointer text-slate-300 hover:bg-slate-700 hover:text-white', 'transition ease-in-out duration-300 group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <LogoutIcon :class="['text-slate-400 group-hover:text-slate-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              Wyloguj
            </div>
          </nav>
        </div>
        <router-link to="/dashboard/profile" class="flex flex-shrink-0 bg-slate-700 p-4">
          <div class="group block w-full flex-shrink-0">
            <div class="flex items-center">
              <div>
                <img class="inline-block h-9 w-9 rounded-full" :src="`https://avatars.dicebear.com/api/initials/${$store.state.userData.name}_${$store.state.userData.surname}.svg`" alt="" />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-white">{{ $store.state.userData.name }} {{ $store.state.userData.surname }}</p>
                <p class="transition ease-in-out duration-300 text-xs font-medium text-slate-300 group-hover:text-slate-200">Zobacz profil</p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex flex-1 flex-col md:pl-64">
      <div class="sticky top-0 z-10 bg-slate-800 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
        <button type="button" class="transition ease-in-out duration-300 -ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-slate-400 hover:text-slate-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import { ref } from 'vue'
    import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import {
    MenuIcon,
    HomeIcon,
    XIcon,
    LogoutIcon,
    CreditCardIcon,
    PhoneIcon,
    DocumentTextIcon,
    FolderIcon
    } from '@heroicons/vue/outline'

export default {
    components: {

    Dialog, DialogPanel, TransitionChild, TransitionRoot,
    MenuIcon,
    HomeIcon,
    XIcon,
    LogoutIcon,
    CreditCardIcon,
    PhoneIcon,
    DocumentTextIcon,
    FolderIcon
  },
  data() {
    return {
      sidebarOpen: false,
      analyticsRule: null,
      navigation: [
        { name: 'Transakcje', href: '/dashboard/transactions', icon: HomeIcon, current: true },
        { name: 'Moje karty', href: '/dashboard/mycards', icon: CreditCardIcon, current: true },
        { name: 'Kontakt', href: '/dashboard/contact', icon: PhoneIcon, current: true },
        { name: 'Faktury', href: '/dashboard/invoices', icon: DocumentTextIcon, current: true },
        { name: 'Umowy', href: '/dashboard/contracts', icon: FolderIcon, current: true },
      ],
    }
  },
  created() {
  },
  mounted(){
  },
  methods: {
    changeLocation(location) {
      if(this.$route.path !== location) {
        if(location === '/dashboard/mycards') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Ładuje użytkownika..",
            subheader: `Nie zamykaj tego okna.`,
          });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        }else {
          this.$router.push(location)
        }

      }
    },
    closeSideBar(permision){
      if(permision){
        this.sidebarOpen = false
      }
    },
    goToAccount(){
      if(this.$store.state.userData.systemCreator === true){
        // this.$router.push('/dashboard/profile?billing=true', '_blank')
        window.open('/dashboard/profile?billing=true', '_blank');
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err) {
        console.log(err.message);
      }
  }
}
}

</script>